@import '../../styles/mixins/spacing';
@import '../../styles/mixins/breakpoints';

.paper {
  padding: 0;
  margin-top: spacing(3);
}

.header {
  position: relative;
}

.title {
  padding: spacing(2, 2, 0, 2);
  margin-bottom: spacing(2);
}

.fab {
  position: absolute;
  right: spacing(2);
  top: spacing(2);
  z-index: 10;
}

.content {
  padding: spacing(0, 2);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: spacing(1.2, 2);
  margin-top: -5px;
  border-radius: 4px;
  // background: #fafafa;
  text-align: right;
  color: #666;
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.label {
  color: #666;

  span {
    font-size: 0.8rem;
  }
}

.form {
  margin-bottom: spacing(4);
}

.value {
  color: #333;
  white-space: pre-wrap;
}

.translationLink {
  margin-left: spacing(1);
  color: #333;
}
