@import '../../styles/mixins/spacing';
@import '../../styles/mixins/breakpoints';

.drawer {
  z-index: 1200;
  width: 58px;
  flex-shrink: 0;
}
.drawerPaper {
  width: 58px;
  padding-top: spacing(8);
  background: #111;
  color: #ccc;
}

.isOpen {
  width: 200px;

  .drawerPaper {
    width: 200px;
  }
}

.divider {
  background-color: rgba(255, 255, 255, 0.12);
}

.listItemIcon {
  min-width: 40px;
  color: #ccc;
}

.version {
  position: absolute;
  bottom: 0.75rem;
  left: 0.75rem;
  font-size: 10px;
}
