$spacing-base: 8px;

@function spacing($t, $r: false, $b: false, $l: false) {
  $tx: $t * $spacing-base;

  @if not $r {
    @return $tx;
  }

  $rx: $r * $spacing-base;

  @if not $b {
    @return $tx $rx;
  }

  $bx: $b * $spacing-base;

  @if not $l {
    @return $tx $rx $bx;
  }

  $lx: $l * $spacing-base;

  @return $tx $rx $bx $lx;
}
