@import '../../styles//mixins/spacing';
@import '../../styles//mixins/breakpoints';

.menuButton {
  margin-right: spacing(2);
}

.title {
  display: none;
  @include breakup('sm') {
    display: block;
    flex-grow: 1;
  }
}

.displayName {
  line-height: spacing(6);
  margin-left: spacing(2);
}

.search {
  position: relative;
  border-radius: 4px;
  background-color: rgba(#fff, 0.15);
  &:hover {
    background-color: rgba(#fff, 0.25);
  }
  margin-right: spacing(2);
  margin-left: 0;
  width: 100%;

  @include breakup('sm') {
    margin-left: spacing(3);
    width: auto;
  }
}

.searchIcon {
  width: spacing(7);
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputRoot {
  color: inherit;
}

.inputInput {
  padding: spacing(1, 1, 1, 7);
  // transition: width; //theme.transitions.create('width'),
  width: 100%;
  @include breakup('md') {
    width: 200px;
  }
}

.sectionDesktop {
  display: none;
  @include breakup('md') {
    display: flex;
  }
}

.sectionMobile {
  display: flex;
  @include breakup('md') {
    display: none;
  }
}

.appBar {
  z-index: 1201;
}
.drawer {
  z-index: 1200;
  width: 200px;
  flex-shrink: 0;
}
.drawerPaper {
  width: 240px;
  padding-top: spacing(8);
  position: relative;
  white-space: nowrap;
}
