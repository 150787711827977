@import '../../styles/mixins/spacing';
@import '../../styles/mixins/breakpoints';

.formControl {
  width: 100%;
  margin: spacing(2, 0);
}

.input {
  width: 100%;
}
