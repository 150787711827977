@import '../../styles/mixins/spacing';
@import '../../styles/mixins/breakpoints';

.paper {
  padding: 0;
  margin-top: spacing(3);
}

.header {
  position: relative;
}

.title {
  padding: spacing(2, 2, 0, 2);
}

.fab {
  position: absolute;
  right: spacing(2);
  top: spacing(2);
  z-index: 10;
}

.content {
  padding: spacing(0, 2);
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: spacing(2);
  margin-top: -5px;
  border-radius: 4px;
  color: #666;
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.label {
  color: #666;

  span {
    font-size: 0.8rem;
  }
}

.created {
  align-self: flex-end;
}

.form {
  margin-bottom: spacing(4);
}

.formControl {
  width: 100%;
  margin: spacing(2, 0);
}

.input {
  width: 100%;
}

.value {
  color: #333;
  max-width: 50%;
}
