@mixin breakup($name) {
  @if $name == 'xs' {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $name == 'sm' {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $name == 'md' {
    @media (min-width: 960px) {
      @content;
    }
  } @else if $name == 'lg' {
    @media (min-width: 1280px) {
      @content;
    }
  } @else if $name == 'xl' {
    @media (min-width: 1920px) {
      @content;
    }
  }
}

@mixin breakdown($name) {
  @if $name == 'xs' {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $name == 'sm' {
    @media (max-width: 959px) {
      @content;
    }
  } @else if $name == 'md' {
    @media (max-width: 1279px) {
      @content;
    }
  } @else if $name == 'lg' {
    @media (max-width: 1919px) {
      @content;
    }
  } @else if $name == 'xl' {
    @media (min-width: 1920px) {
      @content;
    }
  }
}
