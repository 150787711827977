@import '~@fortawesome/fontawesome-free/css/all.css';

html {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
}

.app {
  display: flex;
}

.MuiButton-root {
  margin-right: 8px;
}
